export default {
	full_description: `<p>
								Aleja Rzeczypospolitej 31 lok. 49
								<br />
								02-972 Warszawa
								<br />
								NIP: PL9522191676
							<br />
							<br />
							</p>
							<h3>Biuro i adres do korespondencji</h3>
							<p>
								Ul. Chełmżyńska 180 p. 118
								<br />
								04-464 Warszawa
							<br />
							<br />
							</p>

							<h3>Kierownik Działu Sprzedaży</h3>
							<p>Michał Różanowski</p>
							<a href="tel:+48 730 044 130"><img src="/upload/icon__phone.svg" alt="">
								+48 730 044 130
							</a>
							<a href="mailto:rozanowski.m@dnrgroup.pl"><img src="/upload/icon__mail.svg" alt="">
								rozanowski.m@dnrgroup.pl
							</a>
							<br>
							`,
	agreement:
		'Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w formularzu kontaktowym przez DNR Group Sp. z o.o., ul. Chełmżyńska 180/118 Warszawa w celu kontaktu w związku z przesłanym za pośrednictwem formularza kontaktowego zgłoszeniem.',
};
