export default {
	title: 'Zasady realizacji vouchera',
	full_description: `
	<ul>
		<li>Voucher może być wykorzystany jedynie przez firmę, która go wygrała w loterii wizytówkowej organizowanej podczas konferencji Klubu Przedsiębiorczości w dn. 23.04.2022r.</li>
		<li>Voucher może być wykorzystany jedynie w całości.</li>
		<li>Voucher może być wykorzystany jedynie na usługę: Audyt Bezpieczeństwa Informatycznego.</li>
		<li>Data ważności vouchera – 6 miesięcy od daty wydania. Po upływie tego terminu voucher traci ważność. </li>
		<li>W celu realizacji vouchera zapraszamy do kontaktu pod numerem: +48 508 170 227, e-mail: olszewska.n@dnrgroup.pl </li>
	</ul>
	<h3 style="text-align: center; font-size: 30px;">GRATLUJEMY WYGRANEJ!</h3>
	`,
};
