export default [
	{
		title: 'Helpdesk zdalny i lokalny 24/7',
		thumbnail: '/upload/icon/002-call-center-operator.svg',
		short_description: `<p>Helpdesk to usługa, która pozwala na udzielanie zdalnego lub lokalnego wsparcia IT. Outsourcing IT w ramach helpdesku 24/7 umożliwia skuteczne radzenie sobie z problemami występującymi w ramach infrastruktury, usług IT lub oprogramowania.</p>`,
		full_description: `<p>Helpdesk to usługa, która pozwala na udzielanie zdalnego lub lokalnego wsparcia IT. Outsourcing IT w ramach helpdesku 24/7 umożliwia skuteczne radzenie sobie z problemami występującymi w ramach infrastruktury, usług IT lub oprogramowania. Podstawą w tej kwestii jest szybka reakcja na zgłoszenia – często konieczne jest natychmiastowe działanie, aby zapobiec utracie danych lub ograniczeniu dostępu do oferowanych usług. Nasz helpdesk 24/7 to gwarancja usprawnienia wielu procesów IT zachodzących w firmie.</p>`,
		slug: 'helpdesk-zdalny-i-lokalny-247',
	},
	{
		title: 'Obsługa informatyczna',
		thumbnail: '/upload/icon/laptop.svg',
		short_description: `<p>Obsługa IT pozwala na sprawne i skuteczne realizowanie działań związanych z różnymi aspektami funkcjonowania nowoczesnego biznesu. </p>`,
		full_description: `<p>Obsługa IT pozwala na sprawne i skuteczne realizowanie działań związanych z różnymi aspektami funkcjonowania nowoczesnego biznesu. Pomoc w doborze odpowiednich rozwiązań technologicznych (software i hardware), administracja sieci, szkolenia IT, konfiguracja oprogramowania czy przygotowywanie programów na potrzeby firmy – to jedne z wielu usług, które możemy zaoferować naszym klientom. Powierzając nam zarządzanie nad swoją infrastrukturą IT, możesz mieć pewność efektywnego i szybkiego wykonania zadań oraz stuprocentowej jakości obsługi.</p>`,
		slug: 'obsluga-informatyczna',
	},
	{
		title: 'Doradztwo IT',
		thumbnail: '/upload/icon/004-idea.svg',
		short_description: `<p>Pomagamy przy opracowywaniu strategii, których efektem ma być wniesienie jak największej wartości dodanej do obszaru IT danej firmy.</p>`,
		full_description: `<p>Pomagamy przy opracowywaniu strategii, których efektem ma być wniesienie jak największej wartości dodanej do obszaru IT danej firmy. Doradztwo IT pozwala na zaplanowanie struktur funkcjonowania sfery informatyczno-technicznej w przedsiębiorstwie. Oceniamy, które rozwiązania powinny zostać wdrożone, a które mogą okazać się zbędnym kosztem. Wnosimy do przedsięwzięć naszych klientów wiedzę – podnosimy efektywność firmy poprzez zrozumienie jej funkcjonowania i zastosowanie sprawdzonych rozwiązań.</p>`,
		slug: 'doradztwo-it',
	},
	{
		title: 'Wdrożenie rozwiązań do pracy zdalnej',
		thumbnail: '/upload/icon/005-system.svg',
		short_description: `<p>Home office, czyli praca zdalna w zespole rozproszonym, wymaga nowoczesnych rozwiązań z dziedziny IT.</p>`,
		full_description: `<p>Home office, czyli praca zdalna w zespole rozproszonym, wymaga nowoczesnych rozwiązań z dziedziny IT. Jeśli zależy Ci na maksymalnej wydajności w takim trybie pracy, sięgnij po zaufane, bezpieczne, przejrzyste i wydajne rozwiązania, które możemy przygotować pod kątem potrzeb Twojej firmy. Oferujemy także wsparcie w wyborze adekwatnych narzędzi do poradzenia sobie z wymaganiami stawianymi przez home office. Zawsze dążymy do tego, by praca online mogła być realizowana w bezpiecznym środowisku IT.</p>`,
		slug: 'wdrozenie-rozwiazan-do-pracy-zdalnej',
	},
	{
		title: 'Monitoring informatyczny',
		thumbnail: '/upload/icon/003-graphic-of-stats.svg',
		short_description: `<p>Monitoring IT pozwala na bieżącą kontrolę stanu infrastruktury informatycznej. W efekcie możliwa jest analiza funkcjonowania danego elementu i sprawdzenie, czy działa on w poprawny sposób.</p>`,
		full_description: `<p>Monitoring IT pozwala na bieżącą kontrolę stanu infrastruktury informatycznej. W efekcie możliwa jest analiza funkcjonowania danego elementu i sprawdzenie, czy działa on w poprawny sposób. Monitoring IT dotyczy przede wszystkim funkcjonowania oprogramowania, a także sprzętu. Dzięki analizie hardware i software możliwe jest szybkie i odpowiednie reagowanie na ewentualne niebezpieczeństwa i problemy. W naszym monitoringu informatycznym szczególną uwagę zwracamy na zapewnienie najwyższego standardu bezpieczeństwa.</p>`,
		slug: 'monitoring-informatyczny',
	},
	{
		title: 'Sieci komputerowe i VoIP',
		thumbnail: '/upload/icon/001-globe.svg',
		short_description: `<p>VoIP to nowoczesna technologia połączeń głosowych, która stanowi alternatywę dla konwencjonalnej telefonii, umożliwiając zachowanie wysokiej jakości i stabilności przekazu.</p>`,
		full_description: `<p>VoIP to nowoczesna technologia połączeń głosowych, która stanowi alternatywę dla konwencjonalnej telefonii, umożliwiając zachowanie wysokiej jakości i stabilności przekazu. VoIP pozwala na zintegrowanie ze sobą dużej liczby urządzeń – nawet w przypadku sprzętów różnych typów, na przykład komputerów i telefonów. Wśród zalet takiego rozwiązania należy wymienić wysoki poziom bezpieczeństwa, a także możliwość ograniczenia kosztów skutecznej komunikacji pomiędzy różnymi działami w firmie.</p>`,
		slug: 'sieci-komputerowe-i-voip',
	},
	{
		title: 'Centrale telefoniczne',
		thumbnail: '/upload/icon/009-phone-with-wire.svg',
		short_description: `<p>W ramach skutecznej wymiany informacji pomiędzy działami wciąż wykorzystywana jest infrastruktura telefoniczna. Po tego typu rozwiązania szczególnie często sięgają organizacje związane z sektorem publicznym. </p>`,
		full_description: `<p>W ramach skutecznej wymiany informacji pomiędzy działami wciąż wykorzystywana jest infrastruktura telefoniczna. Po tego typu rozwiązania szczególnie często sięgają organizacje związane z sektorem publicznym. Centrale telefoniczne mogą zapewniać skuteczną komunikację zarówno wewnętrzną, jak i zewnętrzną. Systemy tego rodzaju pozwalają na redukcję kosztów, a dodatkowo cechują się wysokim poziomem zgodności z różnymi rozwiązaniami. Centrale telefoniczne realizowane w ramach DRM Group mogą być zintegrowane z systemami CRM i ERP.</p>`,
		slug: 'centrale-telefoniczne',
	},
	{
		title: 'Hosting i domeny',
		thumbnail: '/upload/icon/010-cloud-computing.svg',
		short_description: `<p>Wśród naszych obszarów działania znajduje się również hosting i świadczenie wszechstronnych usług dla IT w chmurze.</p>`,
		full_description: `<p>Wśród naszych obszarów działania znajduje się również hosting i świadczenie wszechstronnych usług dla IT w chmurze. Stawiamy na bezpieczne, stabilne i wydajne rozwiązania, które sprawią, że Twoje przedsięwzięcia w sieci będą działać niezwykle skutecznie, z pełną dostępnością. Hosting IT w ramach DNR Group zapewnia wysoki poziom bezpieczeństwa i konkurencyjne ceny.  Jest to rozwiązanie skierowane stricte dla IT. Zapewniamy odpowiednie narzędzia, które pozwolą najlepsze wykorzystanie i zaprezentowanie zasobów klienta w sieci.</p>`,
		slug: 'hosting-i-domeny',
	},
	{
		title: 'Bezpieczeństwo IT',
		thumbnail: '/upload/icon/007-shield.svg',
		short_description: `<p>Bezpieczeństwo IT to kluczowy aspekt działania DNR Group. Wiemy, jak wielką wartość dla rozwoju ma zapewnienie dobrze chronionego środowiska – dlatego wszelkie realizowane przez nas usługi zachowują najwyższy standard cyberbezpieczeństwa.</p>`,
		full_description: `<p>Bezpieczeństwo IT to kluczowy aspekt działania DNR Group. Wiemy, jak wielką wartość dla rozwoju ma zapewnienie dobrze chronionego środowiska – dlatego wszelkie realizowane przez nas usługi zachowują najwyższy standard cyberbezpieczeństwa. Działamy w sytuacjach krytycznych, a także wdrażamy rozwiązania, które mają im zapobiegać. Tworzymy strategie, które pozwalają na zachowanie najwyższego poziomu bezpieczeństwa IT i ograniczyć szansę na nieautoryzowany dostęp do zasobów firmy. Nasze inicjatywy realizowane są zgodnie ze standardem ISO 27001.</p>`,
		slug: 'bezpieczenstwo-it',
	},
	{
		title: 'Odzyskiwanie danych',
		thumbnail: '/upload/icon/008-hard-drive.svg',
		short_description: `<p>Odzyskiwanie danych to jedna z usług IT, która nieustannie cieszy się wysokim zainteresowaniem. W ramach oferty DNR Group realizujemy naprawę plików, odzyskiwanie danych z dysków SSD lub HDD i macierzy RAID, a także innych, mniej konwencjonalnych obszarów.</p>`,
		full_description: `<p>Odzyskiwanie danych to jedna z usług IT, która nieustannie cieszy się wysokim zainteresowaniem. W ramach oferty DNR Group realizujemy naprawę plików, odzyskiwanie danych z dysków SSD lub HDD i macierzy RAID, a także innych, mniej konwencjonalnych obszarów. Odzyskiwanie danych realizowane przez DNR Group może dotyczyć praktycznie dowolnego nośnika informacji. Zapewniamy bezpieczeństwo przywrócenia kluczowych informacji, a sama procedura zawsze odbywa się możliwie jak najszybciej.</p>`,
		slug: 'bezpieczenstwo-it',
	},
	{
		title: 'Pamięci masowe (SAN)',
		thumbnail: '/upload/icon/coins.svg',
		short_description: `<p>Storage Area Network pozwala na skuteczne alokowanie zasobów w tych obszarach, które aktualnie wymagają ich najwięcej.</p>`,
		full_description: `<p>Storage Area Network pozwala na skuteczne alokowanie zasobów w tych obszarach, które aktualnie wymagają ich najwięcej. Technologia pamięci masowych SAN znajduje zastosowanie we współczesnych strategiach zarządzania danymi. Celując w najwyższą efektywność z wykorzystaniem nowoczesnej infrastruktury, zdecydowanie warto wziąć pod uwagę takie rozwiązanie. Wdrożenie pamięci masowych jest zalecane w wielu przypadkach – to sposób na zmniejszenie kosztów obsługi i zapewnienie bezawaryjnego działania różnych systemów.</p>`,
		slug: 'pamieci-masowe-san',
	}
];
